import React, { ChangeEvent, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Tab, Tabs } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  getWorkFlowCreateTypeLabel,
  getWorkFlowCreateTypeValue,
  WORKFLOW_CREATE_TYPE,
  WORKFLOW_CREATE_TYPES
} from '@/types/workflows';
import Typography from '@mui/material/Typography';

interface Props {
  isOpen: boolean;
  setOpenAlert: () => void;
  handleClose: () => void;
  type: WORKFLOW_CREATE_TYPE;
  handleAddDefaultFile: (name: string, description: string) => void;
  handleAddWithAIPrompt: (name: string, description: string, prompt: string) => void;
  handleAddWithJSONFile: (name: string, description: string, jsonFile: File) => void;
}

const AddFileDialog = (props: Props) => {
  const {
    isOpen,
    setOpenAlert,
    handleClose,
    type,
    handleAddDefaultFile,
    handleAddWithAIPrompt,
    handleAddWithJSONFile
  } = props;
  const [fileName, setFileName] = useState('');
  const [selectedTab, setSelectedTab] = useState(getWorkFlowCreateTypeValue(type));
  const [fileDescription, setFileDescription] = useState('');
  const [filePrompt, setFilePrompt] = useState('');
  const [jsonFile, setJsonFile] = useState<null | File>(null);

  const handleChange = () => {
    if (fileName === '') return;
    if (selectedTab === 0) {
      handleAddDefaultFile(fileName, fileDescription);
    } else if (selectedTab === 1) {
      handleAddWithAIPrompt(fileName, fileDescription, filePrompt);
    } else if (selectedTab === 2 && jsonFile) {
      handleAddWithJSONFile(fileName, fileDescription, jsonFile);
    }
  };

  const handleCloseDialog = () => {
    setFilePrompt('');
    setJsonFile(null);
    handleClose();
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].type === 'application/json')
        setJsonFile(e.target.files && e.target.files[0]);
      else {
        setOpenAlert();
      }
    }
  };

  const createDisable = (
    selectedTab: number,
    fileName: string,
    filePrompt: string,
    jsonFile: null | File
  ) => {
    if (fileName === '') return false;
    if (selectedTab === 1 && filePrompt === '') return false;
    if (selectedTab === 2 && jsonFile === null) return false;
    return true;
  };
  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} fullWidth={true}>
      <DialogTitle className="font-size-20 font-weight-500">ファイルを新規作成</DialogTitle>
      <Divider />
      <DialogContent>
        <Tabs
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          aria-label="basic tabs example">
          {Object.keys(WORKFLOW_CREATE_TYPES).map((tab, index) => (
            <Tab
              key={`space_project_tab_${index}}`}
              label={getWorkFlowCreateTypeLabel(tab)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Box sx={{ mt: 2 }}>
          <TextField
            required
            id="outlined-required"
            label="ファイル名"
            value={fileName}
            className="w-full"
            onChange={(e) => setFileName(e.target.value)}
          />
          <Box sx={{ mt: 1 }}>
            <TextField
              id="project_description"
              value={fileDescription}
              label="ファイルの説明"
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="説明（任意）"
              className="w-full"
              type="text"
              size="medium"
              multiline
              rows={4}
            />
          </Box>
        </Box>
        {selectedTab === 1 && (
          <Box>
            <Divider variant="middle" flexItem sx={{ mt: 3, mb: 3, ml: 0, mr: 0 }} />
            <Typography variant="body2">プロンプト</Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                id="project_prompt"
                value={filePrompt}
                onChange={(e) => setFilePrompt(e.target.value)}
                placeholder="牛丼屋の業務フローです。入店して、券売機で券を買って、店員に渡して、商品が届く、会計して退出"
                className="w-full"
                type="text"
                size="medium"
                multiline
                rows={5}
              />
            </Box>
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            <Divider variant="middle" flexItem sx={{ mt: 3, mb: 3, ml: 0, mr: 0 }} />
            <Typography variant="body2">Jsonファイル</Typography>
            {jsonFile && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                {jsonFile.name}
              </Typography>
            )}
            <Box sx={{ mt: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                component="label"
                size="medium"
                sx={{ mt: 2 }}>
                ファイルをアップロード
                <input
                  type="file"
                  id="upload-project-image"
                  accept=".json"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          onClick={() => handleChange()}
          className="w-162"
          variant="contained"
          color="primary"
          disabled={!createDisable(selectedTab, fileName, filePrompt, jsonFile)}
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">作成</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFileDialog;
